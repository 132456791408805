import React, { useContext, useEffect, useState } from "react";
import logo from "../../../contents/img/Rampver_Online_Logo-main.png";
import logo2 from "../../../contents/img/Rampver Online Logo Square.png";
import {
  makeStyles,
  useTheme,
  Container,
  CssBaseline,
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";
import * as CONSTANTS from "./constants";
import ClientInformation from "./clientInformation";
import * as ROUTES from "../../../constants/route";
import RiskProfiler from "./riskProfiler";
import Summary from "./summary";
import { AuthUserContext, withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import Functions from "./functions";
import BasicKYCHandler from "../../GenericPages/basicKYCHandler";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { assignStatus } from "../../../services/metrics";

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#256141",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#256141",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Gill Sans, Poppins",
    background: "white",
    height: "100%",
    width: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
    position: "relative",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  navBar: {
    backgroundColor: "#fff",
  },
  brand: {
    maxWidth: "140px",
    margin: "10px auto 13px auto",
  },
  brand2: {
    maxWidth: "140px",
    height: "60px",
    margin: "5px 0 5px 0",
  },
  drawerItemStyle: {
    fontFamily: "Gill Sans, Poppins",
    fontSize: "14px",
  },
  mtb60px: {
    marginTop: "60x",
    marginBottom: "60px",
  },
}));

const LongKYCPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));
  const context = useContext(AuthUserContext);


  const [clientInfo, setClientInfo] = useState(CONSTANTS.INITIAL_STATE.clientInfo);
  const [uploadImg, setUploadImg] = useState(CONSTANTS.INITIAL_STATE.images);
  const [riskProfile, setRiskProfile] = useState(CONSTANTS.INITIAL_STATE.riskProfile);
  const [modalAlert, setModalAlert] = useState(CONSTANTS.INITIAL_STATE.modalAlert);
  const [riskType, setRiskType] = useState({
    riskProfileMessageOpen: false,
    riskProfileType: "",
  });
  const [validation, setValidation] = useState({
    loading: false,
    openDrawer: false,
  });
  const [step, setStep] = useState({
    step: 1,
    fixStep: 1
  });

  const [initialLoad, setInitialLoad] = useState(true);
   const [mariageOption, setMariageOption] = useState("");

  const functions = new Functions();

  useEffect(() => {
    const userData = JSON.parse(window.localStorage.getItem("clientInfo"));
    const bgcolor = "#fff";
    const bodyElt = document.querySelector("body");
    bodyElt.style.backgroundColor = bgcolor;

    setInitialLoad(true);

    const uid = context.uid;
    assignStatus({
      uid,
      status: "SHORT_KYC-1",
    });

    const localUserData = userData !== null && {
      middleName: true,
    };

    const usersRef = props.firebase.db.collection("users");

    usersRef
      .doc(uid)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        if (data.hasLongKYC) {
          window.scrollTo(0, 0);
          setStep((prevState) => ({ ...prevState, step: -1 }))
        } else {
          buildData(userData !== null ? localUserData : data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (clientInfo.name?.middleName !== "" && modalAlert.modalOpen === false) {
      window.localStorage.setItem(
        "marriageOption",
        JSON.stringify({ mariageOption: mariageOption })
      );
      window.localStorage.setItem("clientInfo", JSON.stringify(clientInfo));
      window.localStorage.setItem("riskProfile", JSON.stringify(riskProfile));
    }
  }, [riskProfile, clientInfo, mariageOption]);

  const buildData = (datas) => {
    let clientData;
    let riskData;
    let marriageOptions;
    if (datas.middleName) {
      const clientInfo = JSON.parse(window.localStorage.getItem("clientInfo"));
      const riskProfile = JSON.parse(window.localStorage.getItem("riskProfile"));
      const marriage = JSON.parse(
        window.localStorage.getItem("marriageOption") || "{}"
      );

      clientData = { ...clientInfo }
      riskData = { ...riskProfile };
      marriageOptions = marriage.mariageOption;
    } else {
      const clonedName = { ...clientInfo.name, ...datas };
      clientData = {
        ...clientInfo,
        mobileNumber: datas.mobileNumber.slice(1),
        email: datas.email,
        name: clonedName,
      };
      riskData = { ...riskProfile }
      marriageOptions = { mariageOption };
    }
    setValidation((prevState) => ({ ...prevState, openDrawer: false }))
    setRiskProfile(riskData);
    setClientInfo(clientData)
    setMariageOption(marriageOptions)
    setInitialLoad(false);
  };

  const nextStep = () => {
    window.scrollTo(0, 0);

    setStep((prevState) => ({
      ...prevState,
      step: prevState.step + 1,
    }));
  };

  const handleChangeMarriage = (e) => {
    setMariageOption(e.target.value)
  }

  const fixedStep = (value) => {
    window.scrollTo(0, 0);

    setStep((prevState) => ({
      ...prevState,
      fixStep: prevState.fixStep === 3 ? 3 : value
    }));
  };
  const prevStep = () => {
    window.scrollTo(0, 0);
    setStep((prevState) => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  const handleChangeClientInfo = ({
    cityAddress,
    cityOfBirth,
    civilStatusInput,
    countryAddress,
    countryBirth,
    dateOfBirth,
    email,
    firstName,
    genderInput,
    lastName,
    middleName,
    nationalityInput,
    primaryoccupation,
    provinceAddress,
    selectedIDs,
    sourceIncome,
    street,
    suffix,
    zip,
    SSS,
    TIN,
  }) => {
    setClientInfo((prevState) => ({
      ...prevState,
      name: {
        firstName,
        middleName,
        lastName,
        suffix,
      },
      email,
      gender: genderInput,
      civil: {
        civilStatus: civilStatusInput,
      },
      dateOfBirth,
      placeOfBirth: countryBirth,
      cityOfBirth,
      nationality: nationalityInput,
      ids: {
        idType: selectedIDs,
        SSS: SSS,
        TIN: TIN,
      },
      currentAddress: {
        street,
        city: cityAddress,
        province: provinceAddress,
        zip,
        country: countryAddress,
      },
      work: {
        primaryOccupation: primaryoccupation,
        sourceOfFunds: sourceIncome,
      },
    }));
  };

  const saveAndContinue = () => {
    setValidation((prevState) => ({ ...prevState, loading: true }));

    let requiredFields = [
      { field: clientInfo.name.firstName, name: "First Name" },
      { field: clientInfo.name.middleName, name: "Middle Name" },
      { field: clientInfo.name.lastName, name: "Last Name" },
      { field: clientInfo.name.suffix, name: "Suffix" },
      { field: clientInfo.email, name: "Email" },
      { field: clientInfo.mobileNumber, name: "Mobile Number" },
      { field: clientInfo.gender, name: "Gender" },
      { field: clientInfo.civil.civilStatus, name: "Civil Status" },
      { field: clientInfo.dateOfBirth, name: "Date of Birth" },
      { field: clientInfo.placeOfBirth, name: "Place of Birth" },
      { field: clientInfo.cityOfBirth, name: "City of Birth" },
      { field: clientInfo.nationality, name: "Nationality" },
      { field: clientInfo.currentAddress.street, name: "Street" },
      { field: clientInfo.currentAddress.city, name: "City" },
      { field: clientInfo.currentAddress.province, name: "Province" },
      { field: clientInfo.currentAddress.zip, name: "ZIP" },
      { field: clientInfo.currentAddress.country, name: "Country" },
      {
        field: clientInfo.work.primaryOccupation,
        name: "Primary Occupation",
      },
      { field: clientInfo.work.sourceOfFunds, name: "Source of Funds" },
      { field: clientInfo.ids.idType, name: "ID Type" },
    ];

    if (clientInfo.ids.idType === "SSS") {
      requiredFields = requiredFields.concat([

        {
          field: clientInfo.ids.SSS === undefined ? "" : clientInfo.ids.SSS,
          name: "SSS",
        },
      ]);
    }
    if (clientInfo.ids.idType === "TIN") {
      requiredFields = requiredFields.concat([

        {
          field: clientInfo.ids.TIN === undefined ? "" : clientInfo.ids.TIN,
          name: "TIN",
        },
      ]);
    }
    for (const { field, name } of requiredFields) {
      if (field === "" || field === null) {
        setValidation((prevState) => ({
          ...prevState,
          loading: false,
        }));
        setModalAlert((prevState) => ({
          ...prevState,
          modalOpen: true,
          modalTitle: `The ${name} field is empty. Please complete the form!`,
          modalSuccess: false,
        }));

        return false;
      }
    }
    if (mariageOption === "yes" && clientInfo.civil.civilStatus === "married" && uploadImg.marriageCert === null) {
      setValidation((prevState) => ({
        ...prevState,
        loading: false,
      }));
      setModalAlert((prevState) => ({
        ...prevState,
        modalOpen: true,
        modalTitle: "Please upload your Marriage Certificate.",
        modalSuccess: false,
      }));
      return false
    }
    if (
      uploadImg.idPhotoFront !== null &&
      uploadImg.idPhotoBack !== null &&
      uploadImg.selfie !== null
    ) {
      functions
        .doAddLongKYC(context.uid, clientInfo, riskProfile, uploadImg)
        .then((res) => {
          if (!res.data.ok) throw new Error(res.data.message);
          setValidation((prevState) => ({
            ...prevState,
            loading: false,
          }));
          setModalAlert((prevState) => ({
            ...prevState,
            modalOpen: true,
            modalTitle: "Your Client Application was successfully submitted!",
            modalContent:
              "We will process your application within 2-3 business days. Once approved, you can already start investing!",
            modalSuccess: true,
          }));
        })
        .catch((err) => {
          let errMsg = err.message;
          console.log(errMsg);
          try {
            let errObj = JSON.parse(err.message);
            switch (errObj.fieldname) {
              case "idPhotoFront":
                "front ID photo";
                break;
              case "idPhotoBack":
                "back ID photo";
                break;
              case "selfie":
                errObj.fieldname = "selfie";
                break;
              default:
                break;
            }
            errMsg = `Your uploaded file for your ${errObj.fieldname}, ${errObj.filename}, is not an image or a .pdf document. Please reupload a compatible file!`;
          } catch {
            if (errMsg === "Network Error")
              errMsg =
                "Failed to submit your Client Application due to a network error. Please check your internet connection and try again.";
          }
          setValidation((prevState) => ({
            ...prevState,
            loading: false,
          }));
          setModalAlert((prevState) => ({
            ...prevState,
            modalOpen: true,
            modalTitle: "Uh oh, an error has occurred!",
            modalContent: errMsg,
            modalSuccess: false,
          }));
        });
    } else {
      setValidation((prevState) => ({
        ...prevState,
        loading: false,
      }));
      setModalAlert((prevState) => ({
        ...prevState,
        modalOpen: true,
        modalTitle:
          "Please review your Client Application. You are required to fill up all fields and upload valid ids and a selfie ",
        modalSuccess: false,
      }));
    }
  };

  const handleMobileNumber = (value) => {
    setClientInfo({ ...clientInfo, mobileNumber: value })
  };

  const handleDropzone = (target) => async (files) => {
    setUploadImg((prevState) => ({
      ...prevState,
      [target]: files[0].file,
      [`${target}Label`]: files[0].file.name,
      [`${target}Preview`]: files[0],
    }))
  };
  const handleDropzoneDelete = (target) => async () => {
    setUploadImg((prevState) => ({
      ...prevState,
      [target]: null,
      [`${target}Label`]: "",
      [`${target}Preview`]: null,
    }))
  };

  const handleDrawerOpen = () => {
    setValidation((prevState) => ({
      ...prevState,
      openDrawer: !prevState.openDrawer,
    }));
  };

  const handleMainChange = (target) => (e) => {
    setRiskProfile((prevState) => ({
      ...prevState,
      [target]: e.target.value,
    }));
  };

  const handleRiskMessage = (message) => {
    setRiskType({
      riskProfileType: message,
      riskProfileMessageOpen: true,
    });

  };

  const computeRiskProfile = (riskProfile) => {
    let profileScore = 0;

    profileScore += parseInt(riskProfile.selectedInstrumentsInvested);
    profileScore += parseInt(riskProfile.selectedInvestmentPriority);
    profileScore += parseInt(riskProfile.selectedInvestmentFamiliarity);
    profileScore += parseInt(riskProfile.selectedInvestmentType);
    profileScore += parseInt(riskProfile.selectedIncomeStability);
    profileScore += parseInt(riskProfile.selectedPeriodWithoutRedemption);
    profileScore += parseInt(riskProfile.selectedYearsUntilRetirement);
    profileScore += parseInt(riskProfile.selectedWillingRisk);
    profileScore += parseInt(riskProfile.selectedPortfolioComfort);

    if (profileScore >= 9 && profileScore <= 15) {
      profileScore = "conservative";
    } else if (profileScore >= 16 && profileScore <= 22) {
      profileScore = "moderate";
    } else if (profileScore >= 23 && profileScore <= 27) {
      profileScore = "aggressive";
    } else {
      profileScore = "ERROR";
    }
    return profileScore;
  };

  const handleModalClose = () => {
    setModalAlert((prevState) => ({ ...prevState, modalOpen: false }))
    if (modalAlert.modalSuccess) {
      props.history.push(ROUTES.DASHBOARD);
    }
  };

  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return (
          <ClientInformation
            clientInfo={clientInfo}
            initialLoad={initialLoad}
            mariageOption={mariageOption}
            uploadImg={uploadImg}
            editKYC={false}
            handleMarriage={handleChangeMarriage}
            handleMobileNumber={handleMobileNumber}
            handleDropzone={handleDropzone}
            handleDropzoneDelete={handleDropzoneDelete}
            nextStep={nextStep}
            fixedStep={fixedStep}
            smBreakpoint={smBreakpoint}
            handleChangeData={handleChangeClientInfo}
          />
        );
      case 2:
        return (
          <RiskProfiler
            riskProfile={riskProfile}
            riskType={riskType}
            handleMainChange={handleMainChange}
            prevStep={prevStep}
            nextStep={nextStep}
            fixedStep={fixedStep}
            handleRiskMessage={handleRiskMessage}
            computeRiskProfile={computeRiskProfile}
          />
        );
      case 3:
        return (
          <Summary
            modalAlert={modalAlert}
            loading={validation}
            riskType={riskType}
            clientInfo={clientInfo}
            prevStep={prevStep}
            saveAndContinue={saveAndContinue}
            handleModalClose={handleModalClose}
            isUpdateKYC={true}
          />
        );

      default:
        return <BasicKYCHandler history={props.history} />;
    }
  };

  const drawer = (
    <>
      <div className={classes.drawerHeader}>
        <img className={classes.brand} src={logo2} alt="rampver-logo"></img>

        {!validation.openDrawer ? (
          ""
        ) : (
          <IconButton onClick={() => handleDrawerOpen()}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "white" }} />
            ) : (
              <ChevronRightIcon style={{ color: "white" }} />
            )}
          </IconButton>
        )}
      </div>
      <div className="drawer-container">
        <label className="drawer-title" style={{ color: "white" }}>
          Client Application
        </label>
        <p className="drawer-subtitle" style={{ color: "white" }}>
          Please make sure all your personal information are correct and
          consistent with your government issued ID&apos;s.
        </p>
        <div className="drawer-item-list">
          <List>
            {["Client Information", "Risk Profiler", "Summary"].map(
              (item, index) => {
                const stepNavigation = index + 1;
                return (
                  <ListItem
                    onClick={() =>
                      step.fixStep >= index + 1 &&
                      setStep((prevState) => ({
                        ...prevState,
                        step: stepNavigation,
                      }))
                    }
                    style={{
                      cursor: step.fixStep >= index + 1 ? "pointer" : "default",
                    }}
                    key={index}
                  >
                    <ListItemIcon style={{ marginRight: "0" }}>
                      {step.fixStep > index + 1 ? (
                        <CheckCircleOutlineIcon color="primary" />
                      ) : (
                        <ChevronRightIcon style={{ color: "white" }} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      className={clsx(
                        "list-item-default",
                        step.fixStep > index + 1 ? "process-completed" : "",
                        step.step === index + 1 ? "process-current" : ""
                      )}
                    >
                      <span
                        className={`${step.fixStep >= index + 1 && "sidebar-text"
                          }`}
                      >
                        {" "}
                        {item}{" "}
                      </span>
                    </ListItemText>
                  </ListItem>
                );
              }
            )}
          </List>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, classes.navBar)}
        >
          <Toolbar>
            <IconButton
              style={{ color: "#256141" }}
              aria-label="open drawer"
              onClick={() => handleDrawerOpen()}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <img className={classes.brand2} src={logo} alt="rampver-logo"></img>
          </Toolbar>
        </AppBar>

        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={validation.openDrawer}
            onClose={() => handleDrawerOpen()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div className={classes.mainContent}>

          <main className={clsx(classes.content)}>
            <div className={classes.drawerHeader} />
            <Container component="main">
              {renderSwitch(step.step)}
            </Container>
          </main>
        </div>
      </div>


    </>
  );
};

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(LongKYCPage);

