import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Logo from "../../contents/img/Rampver Online Logo Square.png";
import LogoMobile from "../../contents/img/Rampver-Online-Logo-(Long).png";
import { withFirebase } from "../Firebase";
import {
  DashboardOutlined,
  HistoryOutlined,
  Schedule,
} from "@material-ui/icons";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/route";
import NotificationMenu from "../NotificationMenu";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import Footer from "../Footer";
import Fab from "@material-ui/core/Fab";
import { orange } from "@material-ui/core/colors";
import { Button, Tooltip, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#256141",
    },
  },
  footer: {
    paddingTop: "50px",
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    //zIndex: "1099",
    //paddingTop: "75px",
    backgroundColor: "#256141",
    width: drawerWidth,
  },
  drawerPaperMobile: {
    backgroundColor: "#256141",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Gill Sans, Poppins",
    background: "white",
    height: "100%",
    width: "100%",
  },
  brand: {
    width: "170px",
    height: "auto",
    display: "flex",
    marginLeft: "35px",
    alignItems: "center",
    justifyContent: "center",
  },
  brand2: {
    marginLeft: "40px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoMobile: {
    height: "auto",
    width: "200px",
    marginLeft: "-25px",
    marginTop: "5px",
  },
  iconColor: {
    color: "#246040",
    [theme.breakpoints.down("sm")]: {
      color: "white",
    },
  },
  align_right: {
    position: "absolute",
    right: "25px",
  },
  root_fab: {
    height: 0,
    flexGrow: 1,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    color: "white",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  button: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: "#C8932A",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    "&:hover": {
      backgroundColor: "#b8892e",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
    position: "relative",
  },

  bottomAppBar: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#256141",
    },
  },
}));

function ResponsiveDrawer(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentStep = queryParams.get("step");

  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_nav = Boolean(anchorEl);

  const [notifEl, setNotifEl] = React.useState(false);

  const [accToggler, setAccToggler] = React.useState(false);
  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAccToggler = () => {
    setAccToggler(!accToggler);
  };

  const handleNotifToggle = () => {
    setNotifEl(!notifEl);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    props.firebase.doSignOut();
  };

  const accountProfile = () => {
    history.push(ROUTES.ACCOUNT_PROFILE);
  };

  const startInvest = () => {
    history.push(ROUTES.MY_INVESTMENTS);
  };
  const getVerify = () => {
    history.push(ROUTES.LONG_KYC_LAST);
  };

  const urls = [
    {
      path: ROUTES.DASHBOARD,
      title: "Dashboard",
      icon: <DashboardOutlined />,
    },
    {
      path: "#notifications",
      title: "Notifications",
      icon: <NotificationsOutlinedIcon style={{ color: "white" }} />,
    },
    {
      path: ROUTES.INVESTMENT_HISTORY,
      title: "Investment History",
      icon: <HistoryOutlined />,
    },
    {
      path: ROUTES.RECURRING_INVESTMENT_HISTORY,
      title: "Recurring Investments",
      icon: <Schedule />,
    },
    {
      path: ROUTES.NAVPS,
      title: "Net Asset Value Per Share",
      icon: <TrendingUpIcon />,
    },
    {
      path: ROUTES.FAQS,
      title: "Frequently Asked Questions",
      icon: <HelpOutlineOutlinedIcon />,
    },
    {
      path: "#account",
      title: "Account",
      icon: <AccountCircleOutlinedIcon style={{ color: "white" }} />,
    },
  ];

  const drawer = (
    <div>
      <img className={classes.brand} src={Logo} alt="rampver-logo" />
      <List className="custom-nav-links">
        {urls.map((url, index) => {
          if (url.path === "#notifications") {
            return (
              <ListItem onClick={handleNotifToggle} key={index}>
                <ListItemIcon>{url.icon}</ListItemIcon>
                <ListItemText primary={url.title} style={{ color: "white" }} />
              </ListItem>
            );
          } else if (url.path === "#account") {
            return (
              <React.Fragment key={index}>
                <ListItem onClick={handleAccToggler}>
                  <ListItemIcon>{url.icon}</ListItemIcon>
                  <ListItemText
                    primary={url.title}
                    style={{ color: "white" }}
                  />
                  {accToggler ? (
                    <ExpandLess style={{ color: "white" }} />
                  ) : (
                    <ExpandMore style={{ color: "white" }} />
                  )}
                </ListItem>
                <Collapse in={accToggler} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                      <NavLink
                        style={{ marginLeft: 20, color: "#000" }}
                        to={ROUTES.ACCOUNT_PROFILE}
                      >
                        <span>Profile</span>
                      </NavLink>
                    </ListItem>
                    <ListItem onClick={logout} className={classes.nested}>
                      <NavLink
                        style={{ marginLeft: 20, color: "#000" }}
                        to="#logout"
                      >
                        <span>Logout</span>
                      </NavLink>
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            );
          } else {
            return (
              <ListItem key={index}>
                <NavLink to={url.path}>
                  {url.icon}
                  <span>{url.title}</span>
                </NavLink>
              </ListItem>
            );
          }
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const total = parseFloat(sessionStorage.getItem("total"));

  const tier = parseFloat(sessionStorage.getItem("tier"));
  const approvedKYC = sessionStorage.getItem("approvedLongKYC");
  const isValidated = sessionStorage.getItem("validated");
  const approvedLongKYC =
    approvedKYC !== undefined
      ? approvedKYC
        ? tier !== undefined
          ? tier === 2
          : false
        : false
      : false;
  const spacing = tier === 2 ? "mt-14" : "";

  const isTier2AndValidated =
    tier === 2 && (isValidated === "pending" || isValidated === "declined");
  const isFullyVerified =
    tier === 2 && total >= 50000 && isValidated === "verified";
  const canInvest = tier > 1 || tier === -1;

  const isPaymentLocation = location.pathname.includes('payment');
  let paymentDisplayText = '';

  switch (paymentStep) {
    case '2':
      paymentDisplayText = 'Choose a Payment Option and follow the instructions';
      break;

    case '3':
      paymentDisplayText = "Don't forget to upload your proof of transfer";
      break;

    case '1':
    default:
      paymentDisplayText = 'Enter Amount to be invested';
      break;
  }

  return (
    <div className={`flex mt${spacing}`}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ color: "white" }} />
          </IconButton>
          <div>
            <img
              className={classes.logoMobile}
              src={LogoMobile}
              alt="rampver-logo"
            />
          </div>
          <div className={classes.align_right}>
            {tier === 2 && total >= 50000 ? (
              <Tooltip title="Get Fully Verified Now!" arrow placement="bottom">
                <span>
                  <Button
                    variant="contained"
                    className={classes.button}
                    disabled
                  >
                    Invest Now
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                variant="contained"
                className={classes.button}
                onClick={startInvest}
              >
                {tier > 1 || tier === -1 ? `Invest Now` : `Learn More`}
              </Button>
            )}

            <IconButton>
              <NotificationMenu toggle={notifEl} theme={smBreakpoint} />
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleOutlinedIcon className={classes.iconColor} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open_nav}
              onClose={handleClose}
            >
              <MenuItem onClick={accountProfile}>Account Profile</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {(tier === 2 && isPaymentLocation === false) && (
        <AppBar
          position="fixed"
          // className={classes.bottomAppBar}
          style={{
            backgroundColor: "rgba(255, 217, 102, 1)",
            height: "auto",
            top: "75px",
            zIndex: 1000,
          }}
        >
          <Toolbar>
            <div className=" flex justify-center align-middle py-3 lg:py-0 lg:ml-64 w-52 lg:w-auto text-sm lg:text-lg font-medium font-poppins ">
              You are now able to invest up to P50,000! Want to enjoy the
              complete Rampver Online experience? Get fully verified today!
            </div>
            <div className={classes.align_right}>
              {approvedLongKYC &&
                (tier === 2 &&
                  (isValidated === "pending" || isValidated === "declined") ? (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={startInvest}
                  >
                    Invest Now
                  </Button>
                ) : (
                  <a
                    onClick={getVerify}
                    className=" flex justify-center align-middle text-center w-20 lg:w-auto p-2 lg:m-5 text-sm rounded bg-primary lg:text-base capitalize text-white lg:whitespace-nowrap "
                    style={{ fontFamily: "Gill Sans, Poppins" }}
                  >
                    Get Fully Verified
                  </a>
                ))}
            </div>
          </Toolbar>
        </AppBar>
      )}
      {isPaymentLocation && (
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "rgba(255, 217, 102, 1)",
            height: "fit-content",
            top: "75px",
            zIndex: 1000,
          }}
        >
          <div className="flex justify-center align-middle px-3 py-3 lg:py-0 lg:ml-64 w-52 lg:w-auto text-sm lg:text-lg font-medium font-poppins my-4 w-full">
            {paymentDisplayText}
          </div>
        </AppBar>
      )}
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div
        className={classes.mainContent}
        style={tier === 2 ? { marginTop: "60px" } : { marginTop: "0px" }}
      >
        <main className={classes.content} color="inherit">
          <div className={classes.toolbar} />
          {props.content}
        </main>
        <footer className={classes.footer}>
          <Footer isSignIn={false} />
        </footer>
        {props.disableFab ? (
          ""
        ) : (
          <div className={classes.root_fab}>
            {isFullyVerified ? (
              <Fab
                variant="extended"
                aria-label="InvestBtn"
                className={classes.fab}
                color="primary"
                onClick={getVerify}
                style={{ whiteSpace: "nowrap" }}
              >
                Get Fully Verified!
              </Fab>
            ) : approvedLongKYC && isTier2AndValidated ? (
              <Fab
                variant="extended"
                aria-label="InvestBtn"
                className={classes.fab}
                color="primary"
                onClick={startInvest}
              >
                Invest Now
              </Fab>
            ) : (
              <Fab
                variant="extended"
                aria-label="InvestBtn"
                className={classes.fab}
                color="primary"
                onClick={startInvest}
              >
                {canInvest ? `Invest Now` : `Learn More`}
              </Fab>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withFirebase(ResponsiveDrawer);
