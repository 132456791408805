import React, {  useContext, useEffect, useState } from "react";
import { withAuthorization } from "../../../Session";
import firebase from "firebase/compat/app";
import { compose } from "recompose";
import {
  Container,
  useTheme,
  Paper,
  makeStyles,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Hidden,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { withFirebase } from "../../../Firebase";
import PEPDeclaration from "../PEPDeclaration";
import * as ROUTES from "../../../../constants/route";
import { AuthUserContext } from "../../../Session";
import "firebase/compat/storage";
import * as CONSTANTS from "../constants";
import Functions from "../functions";
import FATCA from "../FATCA";
import Summary from "../summary";
import AdditionalInformation from "../additionalInformation";
import BasicKYCHandler from "../../../GenericPages/basicKYCHandler";
import logo from "../../../../contents/img/Rampver_Online_Logo-main.png";
import logo2 from "../../../../contents/img/Rampver Online Logo Square.png";


const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#256141",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#256141",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Gill Sans, Poppins",
    background: "white",
    height: "100%",
    width: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
    position: "relative",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  navBar: {
    backgroundColor: "#fff",
  },
  brand: {
    maxWidth: "140px",
    margin: "10px auto 13px auto",
  },
  brand2: {
    maxWidth: "140px",
    height: "60px",
    margin: "5px 0 5px 0",
  },
  drawerItemStyle: {
    fontFamily: "Gill Sans, Poppins",
    fontSize: "14px",
  },
  mtb60px: {
    marginTop: "60x",
    marginBottom: "60px",
  },
  fabComments: {
    margin: 0,
    top: "auto",
    right: 40,
    bottom: 40,
    left: "auto",
    position: "fixed",
    minHeight: "300px",
    minWidth: "200px",
    width: "200px",
  },
  commentStyle: {
    position: "fixed",
    width: "77%",
    top: 90,
    right: "auto",
    bottom: "auto",
    left: "auto",
    zIndex: 999,
    padding: "5px",
    borderStyle: "solid",
    borderColor: "#256141",
    borderWidth: "1px",
  },
}));

const DeclinedPage = (props) => {
 const classes = useStyles();
 const theme = useTheme();
 const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));
 const context = useContext(AuthUserContext);

 const [additionalInfo, setAdditionalInfo] = useState(
   CONSTANTS.INITIAL_STATE.additionalInfo
 );
 const [uploadImg, setUploadImg] = useState(CONSTANTS.INITIAL_STATE.images);
 const [pepDeclaration, setPEPDeclaration] = useState(
   CONSTANTS.INITIAL_STATE.PEPDeclaration
 );
 const [fatca, setFATCA] = useState(CONSTANTS.INITIAL_STATE.FATCA);
 const [signature, setSignature] = useState(CONSTANTS.INITIAL_STATE.signature);
 const [modalAlert, setModalAlert] = useState(
   CONSTANTS.INITIAL_STATE.modalAlert
 );
 const [validation, setValidation] = useState({
   loading: false,
   openDrawer: false,
 });
 const [step, setStep] = useState({
   step: 1,
   fixStep: 1,
 });
  const [comments, setComment] = useState({
       showComments: true,
       comments: "",
     });
  const [initialLoad, setInitialLoad] = useState(true);
  const functions = new Functions();
    const storage = firebase.storage().ref();

   useEffect(() => {
     checkIfDeclined();
   }, []);

   const checkIfDeclined = async () => {
     const uid = context.uid;
     let arrPromise = [];
     setInitialLoad(true);

     arrPromise.push(
       props.firebase.db
         .collection("users")
         .doc(uid)
         .collection("KYC")
         .doc("KYC")
         .get()
     );

     arrPromise.push(
       props.firebase.db
         .collection("users")
         .doc(uid)
         .collection("accountDetails")
         .doc("AD")
         .get()
     );

     Promise.all(arrPromise)
       .then((res) => {
         if (res[0].data().validated !== "declined") setStep((prevState) => ({ ...prevState, step: -1 }));

         setInitialState(res[0].data(), res[1].data());
       })
       .catch(() => {
         props.history.push("/404");
       });
   };
const toString = (item) => (item  ? "1" : "0");
   const setInitialState = async (KYC, AD) => {
     let images = KYC.idImages;
     let arrPromise = [];

     arrPromise.push(
       props.firebase.db
         .collection("users")
         .doc(context.uid)
         .collection("riskQuestionnaire")
         .doc("RQ")
         .get()
     );

     images.forEach((item) => {
       arrPromise.push(storage.child(item.url).getDownloadURL());
     });

     Promise.all(arrPromise)
       .then((res) => {
         res.forEach((item, index) => {
           if (index > 0) {
             images[index - 1].url = item;
           }
         });
         const fatcaInfo = {
           usPerson: toString(KYC.FATCA.usPerson),
           usCitizen: toString(KYC.FATCA.usCitizen),
           usResident: toString(KYC.FATCA.usResident),
           usAlien: toString(KYC.FATCA.usAlien),
           usPhone: toString(KYC.FATCA.usPhone),
           usBorn: toString(KYC.FATCA.usBorn),
           usAddressResidence: toString(KYC.FATCA.usAddressResidence),
           usAddressMailing: toString(KYC.FATCA.usAddressMailing),
           usAccount: toString(KYC.FATCA.usAccount),
           usPOA: toString(KYC.FATCA.usPOA),
           usCareOfAddress: toString(KYC.FATCA.usCareOfAddress),
         };
         const pepInfo = {
           governmentWork: toString(KYC.PEP.governmentWork),
           governmentRelative: toString(KYC.PEP.governmentRelative),
           banks: KYC.PEP.banks,
         };
         const additionalInfo = {
           existingID: KYC.ids.idType === "None",
           isPrimaryId: KYC.ids.isPrimaryId,
           ids: {
             idType: KYC.ids.idType,
             SSS: KYC.ids.SSS,
             TIN: KYC.ids.TIN,
           },
           businessAddress: {
             street: KYC.businessAddress.street,
             city: KYC.businessAddress.city,
             province: KYC.businessAddress.province,
             zip: KYC.businessAddress.zip,
             country: KYC.businessAddress.country,
           },
           work: {
             primaryOccupation: KYC.work.primaryOccupation[1],
             nameOfEmployer: KYC.work.nameOfEmployer,
             businessNature: KYC.work.businessNature,
             annualIncomeAmount: KYC.work.annualIncomeAmount,
           },
         };
         const uploadImg = {
           oldIdPhotoFront: getImage(images, "secondary1"),
           oldIdPhotoBack: getImage(images, "secondary2"),
           oldPEPDeclaration: getImage(images, "PEPDocument"),
           idPhotoFront: null,
           idPhotoBack: null,
           idPEPDeclaration: null,
           idPhotoFrontLabel: "Last Uploaded Front ID Photo",
           idPhotoBackLabel: "Last Uploaded Back ID Photo",
           idPEPDeclarationLabel: "Last Uploaded PEPDeclaration Photo",
           idPhotoFrontPreview: null,
           idPhotoBackPreview: null,
           idPEPDeclarationPreview: null,
         };
         setComment((prevState) => ({
           ...prevState,
           comments: KYC.comments,
         }));
         setAdditionalInfo(additionalInfo);
         setPEPDeclaration(pepInfo)
         setFATCA(fatcaInfo)
         setUploadImg(uploadImg);
         setInitialLoad(false);
       })
       .catch((err) => {
         console.log(err);
       });
   };

   const getImage = (imageArray, imageToRetrieve) => {
     let index = imageArray.findIndex((x) => x.image === imageToRetrieve);
     console.log(index)

     if (index === -1) {
       if (imageToRetrieve === "idPhotoFront")
         return imageArray[
           imageArray.findIndex((x) => x.image === "secondary1")
         ].url;
       else if (imageToRetrieve === "idPhotoBack")
         return imageArray[
           imageArray.findIndex((x) => x.image === "secondary2")
         ].url;
       else return null;
     } else return imageArray[index].url;
   };

    let showComments =
      comments.showComments !== undefined ? comments.showComments : true;

       const nextStep = () => {
         window.scrollTo(0, 0);

         setStep((prevState) => ({
           ...prevState,
           step: prevState.step + 1,
         }));
       };

       const fixedStep = (value) => {
         window.scrollTo(0, 0);

         setStep((prevState) => ({
           ...prevState,
           fixStep: prevState.fixStep === 4 ? 4 : value,
         }));
       };
       const prevStep = () => {
         window.scrollTo(0, 0);
         setStep((prevState) => ({
           ...prevState,
           step: prevState.step - 1,
         }));
       };

       const handleChangeAdditionalInfo = (value) => {
         setAdditionalInfo((prevState) => ({
           ...prevState,
           ids: {
             idType: value.selectedIDs,
             SSS: value.SSS,
             TIN: value.TIN,
           },
           businessAddress: {
             street: value.street,
             city: value.cityAddress,
             province: value.provinceAddress,
             zip: value.zip,
             country: value.countryAddress,
           },
           work: {
             primaryOccupation: value.primaryoccupation,
             nameOfEmployer: value.nameOfEmployer,
             businessNature: value.businessNature,
             annualIncomeAmount: value.annualIncomeAmount,
           },
         }));
       };

       const handleChangePEPDec = (value) => {
         if (value.governmentRelative === "0" && value.governmentWork === "0") {
           setPEPDeclaration({
             governmentWork: value.governmentWork,
             governmentRelative: value.governmentRelative,
             banks: "",
           });
           return setUploadImg((prevState) => ({
             ...prevState,
             idPEPDeclaration: null,
             idPEPDeclarationLabel: "",
             idPEPDeclarationPreview: null,
           }));
         } else {
           setPEPDeclaration({
             governmentWork: value.governmentWork,
             governmentRelative: value.governmentRelative,
             banks: value.banks,
           });
         }
       };
       const handleChangeFATCA = (value) => {
         if (value.usPerson === "0") {
           return setFATCA({
             usPerson: value.usPerson,
             usCitizen: "",
             usResident: "",
             usAlien: "",
             usPhone: "",
             usBorn: "",
             usAddressResidence: "",
             usAddressMailing: "",
             usAccount: "",
             usPOA: "",
             usCareOfAddress: "",
           });
         }
         setFATCA({
           usPerson: value.usPerson,
           usCitizen: value.usCitizen,
           usResident: value.usResident,
           usAlien: value.usAlien,
           usPhone: value.usPhone,
           usBorn: value.usBorn,
           usAddressResidence: value.usAddressResidence,
           usAddressMailing: value.usAddressMailing,
           usAccount: value.usAccount,
           usPOA: value.usPOA,
           usCareOfAddress: value.usCareOfAddress,
         });
       };

       const saveAndContinue = () => {
         setValidation((prevState) => ({ ...prevState, loading: true }));

         let requiredFields = [
           { field: additionalInfo.businessAddress.street, name: "Street" },
           { field: additionalInfo.businessAddress.city, name: "City" },
           {
             field: additionalInfo.businessAddress.province,
             name: "Province",
           },
           { field: additionalInfo.businessAddress.zip, name: "ZIP" },
           { field: additionalInfo.businessAddress.country, name: "Country" },
           {
             field: additionalInfo.work.primaryOccupation,
             name: "Primary Occupation",
           },
           {
             field: additionalInfo.work.nameOfEmployer,
             name: "Name of Employer",
           },
           {
             field: additionalInfo.work.businessNature,
             name: "Nature of Business",
           },
           {
             field: additionalInfo.work.annualIncomeAmount,
             name: "Annual Income",
           },
          
         ];
             if (
               additionalInfo.existingID &&
               additionalInfo.ids.idType === "SSS"
             ) {
               requiredFields = requiredFields.concat([
                 { field: additionalInfo.ids.idType, name: "ID Type" },
                 {
                   field:
                     additionalInfo.ids.SSS === undefined
                       ? ""
                       : additionalInfo.ids.SSS,
                   name: "SSS",
                 },
               ]);
             }
             if (
               additionalInfo.existingID &&
               additionalInfo.ids.idType === "TIN"
             ) {
               requiredFields = requiredFields.concat([
                 { field: additionalInfo.ids.idType, name: "ID Type" },
                 {
                   field:
                     additionalInfo.ids.TIN === undefined
                       ? ""
                       : additionalInfo.ids.TIN,
                   name: "TIN",
                 },
               ]);
             } 
         if (fatca.usPerson === "1") {
           requiredFields = requiredFields.concat([
             {
               field: fatca.usPerson === undefined ? "" : fatca.usPerson,
               name: "US Person",
             },
             {
               field: fatca.usCitizen === undefined ? "" : fatca.usCitizen,
               name: "US Citizen",
             },
             {
               field: fatca.usResident === undefined ? "" : fatca.usResident,
               name: "US Resident",
             },
             {
               field: fatca.usAlien === undefined ? "" : fatca.usAlien,
               name: "US Resident Alien",
             },
             {
               field: fatca.usPhone === undefined ? "" : fatca.usPhone,
               name: "US Telephone Number",
             },
             {
               field: fatca.usBorn === undefined ? "" : fatca.usBorn,
               name: "Born in US",
             },
             {
               field:
                 fatca.usAddressResidence === undefined
                   ? ""
                   : fatca.usAddressResidence,
               name: "US Residence Address",
             },
             {
               field:
                 fatca.usAddressMailing === undefined
                   ? ""
                   : fatca.usAddressMailing,
               name: "US Mailing Address",
             },
             {
               field: fatca.usAccount === undefined ? "" : fatca.usAccount,
               name: "US Account",
             },
             {
               field: fatca.usPOA === undefined ? "" : fatca.usPOA,
               name: "US POA",
             },
             {
               field:
                 fatca.usCareOfAddress === undefined
                   ? ""
                   : fatca.usCareOfAddress,
               name: "US Care Of Address",
             },
           ]);
         }

         if (
           pepDeclaration.governmentWork === "1" ||
           pepDeclaration.governmentRelative === "1"
         ) {
           requiredFields = requiredFields.concat([
             { field: pepDeclaration.governmentWork, name: "Government Work" },
             {
               field: pepDeclaration.governmentRelative,
               name: "Government Relative",
             },
             { field: pepDeclaration.banks, name: "Banks" },
           ]);
         }

         for (const { field, name } of requiredFields) {
           if (field === "" || field === null) {
             setValidation((prevState) => ({
               ...prevState,
               loading: false,
             }));
             setModalAlert((prevState) => ({
               ...prevState,
               modalOpen: true,
               modalTitle: `The ${name} field is empty. Please complete the form!`,
               modalSuccess: false,
             }));
             return false;
           }
         }
         const pepDec =
           uploadImg.oldPEPDeclaration === null
             ? uploadImg.idPEPDeclaration === null
             : false;
         if (
           (pepDeclaration.governmentWork === "1" ||
             pepDeclaration.governmentRelative === "1") &&
           pepDec
         ) {
           setValidation((prevState) => ({
             ...prevState,
             loading: false,
           }));
           setModalAlert((prevState) => ({
             ...prevState,
             modalOpen: true,
             modalTitle: "Please upload your PEP Declaration.",
             modalSuccess: false,
           }));
           return false;
         }

          const frontImg =
            uploadImg.oldIdPhotoFront === null
              ? uploadImg.idPhotoFront === null
              : false;
          const rearImg =
            uploadImg.oldIdPhotoBack === null
              ? uploadImg.idPhotoBack === null
              : false;
              console.log(
                (additionalInfo.isPrimaryId === undefined ||
                  additionalInfo.isPrimaryId === false) &&
                  (frontImg || rearImg)
              );
         if (
           (additionalInfo.isPrimaryId === undefined ||
             additionalInfo.isPrimaryId === false) &&
           (frontImg || rearImg)
         ) {
           setValidation((prevState) => ({
             ...prevState,
             loading: false,
           }));
           return setModalAlert((prevState) => ({
             ...prevState,
             modalOpen: true,
             modalTitle: "Please upload your ID.",
             modalSuccess: false,
           }));
         } 
         else {
           functions
             .doUpdateLongKYC(
               context.uid,
               uploadImg,
               additionalInfo,
               pepDeclaration,
               signature,
               fatca
             )
             .then((res) => {
               if (!res.data.ok) throw new Error(res.data.message);
               setValidation((prevState) => ({
                 ...prevState,
                 loading: false,
               }));
               return setModalAlert((prevState) => ({
                 ...prevState,
                 modalOpen: true,
                 modalTitle:
                   "Your Client Application was successfully submitted!",
                 modalContent: ` <div class=" px-0 lg:px-12">
                 Please keep an eye on your email inbox for the next steps. 
               We may ask to schedule a quick zoom call with you to approve your application as part of AMLA's guidelines.
                <p class="text-green-600 px-5 lg:px-10"">
                        In the meantime, you can already invest up to P50,000 in
                        a recommended fund!
                      </p>
                      <p>
                        For any concerns regarding your application, email us at
                        <a
                          href="mailto:online@rampver.com"
                           class="text-blue-600"
                        >
                          online@rampver.com
                        </a>
                      </p>
                      </div>
               `,
                 modalSuccess: true,
               }));
             })
             .catch((err) => {
               let errMsg = err.message;
               console.log(errMsg);
               try {
                 let errObj = JSON.parse(err.message);

                 errMsg = `Your uploaded file for your ${errObj.fieldname}, ${errObj.filename}, is not an image or a .pdf document. Please reupload a compatible file!`;
               } catch {
                 if (errMsg === "Network Error")
                   errMsg =
                     "Failed to submit your Client Application due to a network error. Please check your internet connection and try again.";
               }
               setValidation((prevState) => ({
                 ...prevState,
                 loading: false,
               }));
               setModalAlert((prevState) => ({
                 ...prevState,
                 modalOpen: true,
                 modalTitle: "Uh oh, an error has occurred!",
                 modalContent: errMsg,
                 modalSuccess: false,
               }));
             });
         }
       };

       const handleDropzone = (target) => async (files) => {
         setUploadImg((prevState) => ({
           ...prevState,
           [target]: files[0].file,
           [`${target}Label`]: files[0].file.name,
           [`${target}Preview`]: files[0],
         }));
       };
       const handleDropzoneDelete = (target) => async () => {
         setUploadImg((prevState) => ({
           ...prevState,
           [target]: null,
           [`${target}Label`]: "",
           [`${target}Preview`]: null,
         }));
       };

       const handleDrawerOpen = () => {
         setValidation((prevState) => ({
           ...prevState,
           openDrawer: !prevState.openDrawer,
         }));
       };

       const handleModalClose = () => {
         setModalAlert((prevState) => ({ ...prevState, modalOpen: false }));
         if (modalAlert.modalSuccess) {
           props.history.push(ROUTES.DASHBOARD);
         }
       };

       const handleSignature = (url) => {
         setSignature({ signatureUrl: url });
       };

       const handleEraseCurrentSig = () => {
         setSignature({ signatureUrl: null });
       };

       const renderSwitch = (step) => {
         switch (step) {
           case 1:
             return (
               <AdditionalInformation
                 additionalInfo={additionalInfo}
                 uploadImg={uploadImg}
                 editKYC={true}
                 handleDropzone={handleDropzone}
                 handleDropzoneDelete={handleDropzoneDelete}
                 nextStep={nextStep}
                 fixedStep={fixedStep}
                 smBreakpoint={smBreakpoint}
                 handleChangeData={handleChangeAdditionalInfo}
                 initialLoad={initialLoad}
               />
             );
           case 2:
             return (
               <PEPDeclaration
                 PEPData={pepDeclaration}
                 uploadImg={uploadImg}
                 prevStep={prevStep}
                 editKYC={true}
                 handleDropzone={handleDropzone}
                 handleDropzoneDelete={handleDropzoneDelete}
                 nextStep={nextStep}
                 fixedStep={fixedStep}
                 handleChangePEPDec={handleChangePEPDec}
                 initialLoad={initialLoad}
               />
             );
           case 3:
             return (
               <FATCA
                 FATCAData={fatca}
                 prevStep={prevStep}
                 editKYC={true}
                 nextStep={nextStep}
                 fixedStep={fixedStep}
                 handleChangeFATCA={handleChangeFATCA}
                 initialLoad={initialLoad}
               />
             );
           case 4:
             return (
               <Summary
                 modalAlert={modalAlert}
                 loading={validation}
                 additionalInfo={additionalInfo}
                 PEPData={pepDeclaration}
                 FATCAData={fatca}
                 signature={signature}
                 prevStep={prevStep}
                 handleSignature={handleSignature}
                 handleEraseCurrentSig={handleEraseCurrentSig}
                 saveAndContinue={saveAndContinue}
                 handleModalClose={handleModalClose}
                 isUpdateKYC={true}
               />
             );

           default:
             return <BasicKYCHandler history={props.history} />;
         }
       };
         const stringToWhiteSpaceConverter = (text) => {
           let returnText = text.replace(/\/n /gi, `\n`);
           return returnText;
         };
         const toggleComments = () => {
           setComment((prevState) => ({
             ...prevState,
             showComments: !prevState.showComments,
           }));
         };

       const drawer = (
         <>
           <div className={classes.drawerHeader}>
             <img
               className={classes.brand}
               src={logo2}
               alt="rampver-logo"
             ></img>

             {!validation.openDrawer ? (
               ""
             ) : (
               <IconButton onClick={() => handleDrawerOpen()}>
                 {theme.direction === "ltr" ? (
                   <ChevronLeftIcon style={{ color: "white" }} />
                 ) : (
                   <ChevronRightIcon style={{ color: "white" }} />
                 )}
               </IconButton>
             )}
           </div>
           <div className="drawer-container">
             <label className="drawer-title" style={{ color: "white" }}>
               Additional Application
             </label>
             <p className="drawer-subtitle" style={{ color: "white" }}>
               Please make sure all your personal information are correct and
               consistent with your government issued ID&apos;s.
             </p>
             <div className="drawer-item-list">
               <List>
                 {[
                   "Additional Information",
                   "PEP Declaration",
                   "FATCA",
                   "Summary",
                 ].map((item, index) => {
                   const stepNavigation = index + 1;
                   return (
                     <ListItem
                       onClick={() =>
                         step.fixStep >= index + 1 &&
                         setStep((prevState) => ({
                           ...prevState,
                           step: stepNavigation,
                         }))
                       }
                       style={{
                         cursor:
                           step.fixStep >= index + 1 ? "pointer" : "default",
                       }}
                       key={index}
                     >
                       <ListItemIcon style={{ marginRight: "0" }}>
                         {step.fixStep > index + 1 ? (
                           <CheckCircleOutlineIcon color="primary" />
                         ) : (
                           <ChevronRightIcon style={{ color: "white" }} />
                         )}
                       </ListItemIcon>
                       <ListItemText
                         className={clsx(
                           "list-item-default",
                           step.fixStep > index + 1 ? "process-completed" : "",
                           step.step === index + 1 ? "process-current" : ""
                         )}
                       >
                         <span
                           className={`${
                             step.fixStep >= index + 1 && "sidebar-text"
                           }`}
                         >
                           {" "}
                           {item}{" "}
                         </span>
                       </ListItemText>
                     </ListItem>
                   );
                 })}
               </List>
             </div>
           </div>
         </>
       );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, classes.navBar)}
        >
          <Toolbar>
            <IconButton
              style={{ color: "#256141" }}
              aria-label="open drawer"
              onClick={() => handleDrawerOpen()}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <img className={classes.brand2} src={logo} alt="rampver-logo"></img>
          </Toolbar>
        </AppBar>

        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={validation.openDrawer}
            onClose={() => handleDrawerOpen()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div className={classes.mainContent}>
          <main className={clsx(classes.content)}>
            <div>
              {smBreakpoint ? (
                <Paper elevation={6} className={classes.commentStyle}>
                  <IconButton onClick={(e) => toggleComments(e)}>
                    {showComments ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                  </IconButton>
                  <b>Admin Comments: </b>
                  <br />
                  {showComments ? (
                    <div style={{ overflow: "auto", width: "100%" }}>
                      <span style={{ "white-space": "pre-line" }}>
                        {/* {comments.comments}  */}
                        {stringToWhiteSpaceConverter(comments.comments)}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </Paper>
              ) : (
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  severity="warning"
                  style={{
                    position: "fixed",
                    zIndex: "999",
                    backgroundColor: "#256141",
                    top: "80px",
                    width: "85%",
                  }}
                  icon={
                    <IconButton onClick={(e) => toggleComments(e)}>
                      {showComments ? (
                        <ArrowDropDownIcon style={{ color: "white" }} />
                      ) : (
                        <ArrowDropUpIcon style={{ color: "white" }} />
                      )}
                    </IconButton>
                  }
                >
                  <b>Admin Comments: </b>
                  <br />
                  {showComments ? (
                    <div style={{ overflow: "auto", width: "100%" }}>
                      <span style={{ "white-space": "pre-line" }}>
                        {/* {comments.comments} */}
                        {stringToWhiteSpaceConverter(comments.comments)}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </MuiAlert>
              )}
            </div>
            <div>
              <h2>Edit Client Application</h2>
            </div>
            <div className={classes.drawerHeader} />
            <Container component="main">{renderSwitch(step.step)}</Container>
          </main>
        </div>
      </div>
    </>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withAuthorization(condition),
  withFirebase
)(DeclinedPage);


