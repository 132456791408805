import React, { useContext, useEffect, useState } from "react";
import { withAuthorization, AuthUserContext } from "../../../Session";
import { withFirebase } from "../../../Firebase";
import firebase from "firebase/compat/app";
import { compose } from "recompose";
import {
  Container,
  useTheme,
  Paper,
  makeStyles,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Drawer,
  Hidden,
  AppBar,
  Toolbar,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import * as ROUTES from "../../../../constants/route";
import "firebase/compat/storage";

import ClientInformation from "../clientInformation";
import RiskProfiler from "../riskProfiler";
import Summary from "../summary";

import * as CONSTANTS from "../constants";
import Functions from "../functions";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";
import logo from "../../../../contents/img/Rampver_Online_Logo-main.png";
import MenuIcon from "@material-ui/icons/Menu";
import logo2 from "../../../../contents/img/Rampver Online Logo Square.png";
import BasicKYCHandler from "../../../GenericPages/basicKYCHandler";

const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#256141",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#256141",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Gill Sans, Poppins",
    background: "white",
    height: "100%",
    width: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
    position: "relative",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  navBar: {
    backgroundColor: "#fff",
  },
  brand: {
    maxWidth: "140px",
    margin: "10px auto 13px auto",
  },
  brand2: {
    maxWidth: "140px",
    height: "60px",
    margin: "5px 0 5px 0",
  },
  drawerItemStyle: {
    fontFamily: "Gill Sans, Poppins",
    fontSize: "14px",
  },
  mtb60px: {
    marginTop: "60x",
    marginBottom: "60px",
  },
  fabComments: {
    margin: 0,
    top: "auto",
    right: 40,
    bottom: 40,
    left: "auto",
    position: "fixed",
    minHeight: "300px",
    minWidth: "200px",
    width: "200px",
  },
  commentStyle: {
    position: "fixed",
    width: "77%",
    top: 90,
    right: "auto",
    bottom: "auto",
    left: "auto",
    zIndex: 999,
    padding: "5px",
    borderStyle: "solid",
    borderColor: "#256141",
    borderWidth: "1px",
  },
}));

const DeclinedPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();

    const [clientInfo, setClientInfo] = useState(
      CONSTANTS.INITIAL_STATE.clientInfo
    );
    const [uploadImg, setUploadImg] = useState(CONSTANTS.INITIAL_STATE.images);
    const [riskProfile, setRiskProfile] = useState(
      CONSTANTS.INITIAL_STATE.riskProfile
    );
    const [modalAlert, setModalAlert] = useState(
      CONSTANTS.INITIAL_STATE.modalAlert
    );
    const [riskType, setRiskType] = useState({
      riskProfileMessageOpen: false,
      riskProfileType: "",
    });
    const [validation, setValidation] = useState({
      loading: false,
      openDrawer: false,
    });
    const [step, setStep] = useState({
      step: 1,
      fixStep: 1,
    });
    const [comments, setComment] = useState({
      showComments: true,
      comments: "",
    });
  const [initialLoad, setInitialLoad] = useState(true);
 const [mariageOption, setMariageOption] = useState("");
  const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));
  const functions = new Functions();

  const context = useContext(AuthUserContext);
  const storage = firebase.storage().ref();


  const nextStep = () => {
    window.scrollTo(0, 0);
    setStep((prevState) => ({
      ...prevState,
      step: prevState.step + 1,
    }));
  };

  const fixedStep = (value) => {
    window.scrollTo(0, 0);

    setStep((prevState) => ({
      ...prevState,
      fixStep: prevState.fixStep === 3 ? 3 : value,
    }));
  };
  const prevStep = () => {
    window.scrollTo(0, 0);
    setStep((prevState) => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  const handleChangeClientInfo = ({
    cityAddress,
    cityOfBirth,
    civilStatusInput,
    countryAddress,
    countryBirth,
    dateOfBirth,
    email,
    firstName,
    genderInput,
    lastName,
    middleName,
    nationalityInput,
    primaryoccupation,
    provinceAddress,
    selectedIDs,
    sourceIncome,
    street,
    suffix,
    zip,
    SSS,
    TIN,
  }) => {
    setClientInfo((prevState) => ({
      ...prevState,
      name: {
        firstName,
        middleName,
        lastName,
        suffix,
      },
      email,
      gender: genderInput,
      civil: {
        civilStatus: civilStatusInput,
      },
      dateOfBirth,
      placeOfBirth: countryBirth,
      cityOfBirth,
      nationality: nationalityInput,
      ids: {
        idType: selectedIDs,
        SSS: SSS,
        TIN: TIN,
      },
      currentAddress: {
        street,
        city: cityAddress,
        province: provinceAddress,
        zip,
        country: countryAddress,
      },
      work: {
        primaryOccupation: primaryoccupation,
        sourceOfFunds: sourceIncome,
      },
    }));
  };

  useEffect(() => {
    checkIfDeclined();

  }, []);

    const checkIfDeclined = async () => {
      const uid = context.uid;
      let arrPromise = [];
setInitialLoad(true);
      arrPromise.push(
        props.firebase.db
          .collection("users")
          .doc(uid)
          .collection("KYC")
          .doc("KYC")
          .get()
      );

      arrPromise.push(
        props.firebase.db
          .collection("users")
          .doc(uid)
          .collection("accountDetails")
          .doc("AD")
          .get()
      );

      Promise.all(arrPromise)
        .then((res) => {
          if (res[0].data().validated !== "declined")
           setStep((prevState) => ({ ...prevState, step: -1 }));

          setInitialState(res[0].data(), res[1].data());
        })
        .catch(() => {
          props.history.push("/404");
        });
    };

   const setInitialState = async (KYC, AD) => {
       let images = KYC.idImages;
       let arrPromise = [];

       arrPromise.push(
         props.firebase.db
           .collection("users")
           .doc(context.uid)
           .collection("riskQuestionnaire")
           .doc("RQ")
           .get()
       );

       images.forEach((item) => {
         arrPromise.push(storage.child(item.url).getDownloadURL());
       });

       Promise.all(arrPromise)
         .then((res) => {
           res.forEach((item, index) => {
             if (index > 0) {
               images[index - 1].url = item;
             }
           });

            const clientInfo = {
              name: {
                firstName: KYC.name.firstName,
                middleName: KYC.name.middleName,
                lastName: KYC.name.lastName,
                suffix: KYC.name.suffix,
              },
              email: KYC.email,
              mobileNumber: KYC.mobileNumber,
              gender: KYC.gender,
              civil: {
                civilStatus: KYC.civil.civilStatus,
              },
              dateOfBirth: KYC.dateOfBirth,
              placeOfBirth: KYC.placeOfBirth,
              cityOfBirth: KYC.cityOfBirth,
              nationality: KYC.nationality,
              ids: {
                idType: KYC.ids.idType,
                SSS: KYC.ids.SSS,
                TIN: KYC.ids.TIN,
              },
              currentAddress: {
                street: KYC.currentAddress.street,
                city: KYC.currentAddress.city,
                province: KYC.currentAddress.province,
                zip: KYC.currentAddress.zip,
                country: KYC.currentAddress.country,
              },
              work: {
                primaryOccupation: KYC.work.primaryOccupation,
                sourceOfFunds: KYC.work.sourceOfFunds,
              },
            };
            const riskProfile = {
              selectedAnnualIncome: "",
              selectedInstrumentsInvested: getRiskQuestion(
                res[0].data().instrumentsInvested
              ),
              selectedInvestmentPriority: getRiskQuestion(
                res[0].data().investmentPriority
              ),
              selectedInvestmentFamiliarity: getRiskQuestion(
                res[0].data().investmentFamiliarity
              ),
              selectedInvestmentType: getRiskQuestion(
                res[0].data().investmentType
              ),
              selectedIncomeStability: getRiskQuestion(
                res[0].data().incomeStability
              ),
              selectedPeriodWithoutRedemption: getRiskQuestion(
                res[0].data().periodWithoutRedemption
              ),
              selectedYearsUntilRetirement: getRiskQuestion(
                res[0].data().yearsUntilRetirement
              ),
              selectedWillingRisk: getRiskQuestion(res[0].data().willingRisk),
              selectedPortfolioComfort: getRiskQuestion(
                res[0].data().portfolioComfort
              ),
            };
            const uploadImg = {
              oldIdPhotoFront: getImage(images, "idPhotoFront"),
              oldIdPhotoBack: getImage(images, "idPhotoBack"),
              oldSelfie: getImage(images, "selfie"),
              oldMarriageCert: getImage(images, "marriageCert"),
              idPhotoFront: "",
              idPhotoBack: "",
              selfie: "",
              marriageCert: "",
              idPhotoFrontLabel: "Last Uploaded Front ID Photo",
              idPhotoBackLabel: "Last Uploaded Back ID Photo",
              selfieLabel: "Last Uploaded Selfie",
              marriageCertLabel: "Last Uploaded Marriage Certificate",
              marriageCertPreview: null,
              idPhotoFrontPreview: null,
              idPhotoBackPreview: null,
              selfiePreview: null,
            };
            setComment((prevState) => ({
              ...prevState,
              comments:KYC.comments
            }));
            const marCertOption =
              clientInfo.civil.civilStatus !== "married"
                ? ""
                : uploadImg.oldMarriageCert
                ? "yes"
                : "no";
             setClientInfo(clientInfo)
             setRiskProfile(riskProfile)
             setUploadImg(uploadImg)
             setMariageOption(marCertOption);
             setInitialLoad(false);

        })
         .catch((err) => {
           console.log(err);
         });
     };
    const getImage = (imageArray, imageToRetrieve) => {
        let index = imageArray.findIndex((x) => x.image === imageToRetrieve);

        if (index === -1) {
          if (imageToRetrieve === "idPhotoFront")
            return imageArray[
              imageArray.findIndex((x) => x.image === "secondary1")
            ].url;
          else if (imageToRetrieve === "idPhotoBack")
            return imageArray[
              imageArray.findIndex((x) => x.image === "secondary2")
            ].url;
          else return null;
        } else return imageArray[index].url;
      };

    const getRiskQuestion = (itemObj) => {
      for (var key in itemObj) {
        if (itemObj.hasOwnProperty(key)) {
          if (itemObj[key] === true) {
            let returnValue = parseInt(key.slice(key.length - 1));
            return returnValue;
          }
        }
      }
    };
      const handleChangeMarriage = (e) => {
        setMariageOption(e.target.value);
      };


 const saveAndContinue = () => {
   setValidation((prevState) => ({ ...prevState, loading: true }));
   let requiredFields = [
     { field: clientInfo.name.firstName, name: "First Name" },
     { field: clientInfo.name.middleName, name: "Middle Name" },
     { field: clientInfo.name.lastName, name: "Last Name" },
     { field: clientInfo.name.suffix, name: "Suffix" },
     { field: clientInfo.email, name: "Email" },
     { field: clientInfo.mobileNumber, name: "Mobile Number" },
     { field: clientInfo.gender, name: "Gender" },
     { field: clientInfo.civil.civilStatus, name: "Civil Status" },
     { field: clientInfo.dateOfBirth, name: "Date of Birth" },
     { field: clientInfo.placeOfBirth, name: "Place of Birth" },
     { field: clientInfo.cityOfBirth, name: "City of Birth" },
     { field: clientInfo.nationality, name: "Nationality" },
     { field: clientInfo.currentAddress.street, name: "Street" },
     { field: clientInfo.currentAddress.city, name: "City" },
     { field: clientInfo.currentAddress.province, name: "Province" },
     { field: clientInfo.currentAddress.zip, name: "ZIP" },
     { field: clientInfo.currentAddress.country, name: "Country" },
     {
       field: clientInfo.work.primaryOccupation,
       name: "Primary Occupation",
     },
     { field: clientInfo.work.sourceOfFunds, name: "Source of Funds" },
     { field: clientInfo.ids.idType, name: "ID Type" },
   ];

   if (clientInfo.ids.idType === "SSS") {
     requiredFields = requiredFields.concat([
       {
         field: clientInfo.ids.SSS === undefined ? "" : clientInfo.ids.SSS,
         name: "SSS",
       },
     ]);
   }
   if (clientInfo.ids.idType === "TIN") {
     requiredFields = requiredFields.concat([
       {
         field: clientInfo.ids.TIN === undefined ? "" : clientInfo.ids.TIN,
         name: "TIN",
       },
     ]);
   } 

   for (const { field, name } of requiredFields) {
     if (field === "" || field === null) {
       setValidation((prevState) => ({
         ...prevState,
         loading: false,
       }));
       setModalAlert((prevState) => ({
         ...prevState,
         modalOpen: true,
         modalTitle: `The ${name} field is empty. Please complete the form!`,
         modalSuccess: false,
       }));
       return false;
     }
   }
   const marriageCert =
     uploadImg.oldMarriageCert === null 
       ? uploadImg.marriageCert === null : false ;
   if (clientInfo.civil.civilStatus === "married" && marriageCert) {
     setValidation((prevState) => ({
       ...prevState,
       loading: false,
     }));
     setModalAlert((prevState) => ({
       ...prevState,
       modalOpen: true,
       modalTitle: "Please upload your Marriage Certificate.",
       modalSuccess: false,
     }));
     return false;
   }
   if (
     uploadImg.oldIdPhotoFront !== null &&
     uploadImg.oldIdPhotoBack !== null &&
     uploadImg.oldSelfie !== null
   ) {
     functions
       .doUpdateLongKYC(context.uid, clientInfo, riskProfile, uploadImg)
       .then((res) => {
         if (!res.data.ok) throw new Error(res.data.message);
         setValidation((prevState) => ({
           ...prevState,
           loading: false,
         }));
         setModalAlert((prevState) => ({
           ...prevState,
           modalOpen: true,
           modalTitle: "Your Client Application was successfully submitted!",
           modalContent:
             "We will process your application within 2-3 business days. Once approved, you can already start investing!",
           modalSuccess: true,
         }));
       })
       .catch((err) => {
         let errMsg = err.message;
         try {
           let errObj = JSON.parse(err.message);
           switch (errObj.fieldname) {
             case "idPhotoFront":
               "front ID photo";
               break;
             case "idPhotoBack":
               "back ID photo";
               break;
             case "selfie":
               errObj.fieldname = "selfie";
               break;
             default:
               break;
           }
           errMsg = `Your uploaded file for your ${errObj.fieldname}, ${errObj.filename}, is not an image or a .pdf document. Please reupload a compatible file!`;
         } catch {
           if (errMsg === "Network Error")
             errMsg =
               "Failed to submit your Client Application due to a network error. Please check your internet connection and try again.";
         }
         setValidation((prevState) => ({
           ...prevState,
           loading: false,
         }));
         setModalAlert((prevState) => ({
           ...prevState,
           modalOpen: true,
           modalTitle: "Uh oh, an error has occurred!",
           modalContent: errMsg,
           modalSuccess: false,
         }));
       });
   } else {
     setValidation((prevState) => ({
       ...prevState,
       loading: false,
     }));
     setModalAlert((prevState) => ({
       ...prevState,
       modalOpen: true,
       modalTitle:
         "Please review your Client Application. You are required to fill up all fields and upload valid ids and a selfie ",
       modalSuccess: false,
     }));
   }
 };

  const handleMobileNumber = (value) => {
    setClientInfo({ ...clientInfo, mobileNumber: value });
  };

  const handleDropzone = (target) => async (files) => {
    setUploadImg((prevState) => ({
      ...prevState,
      [target]: files[0].file,
      [`${target}Label`]: files[0].file.name,
      [`${target}Preview`]: files[0],
    }));
  };
  const handleDropzoneDelete = (target) => async () => {
    setUploadImg((prevState) => ({
      ...prevState,
      [target]: null,
      [`${target}Label`]: "",
      [`${target}Preview`]: null,
    }));
  };

  const handleDrawerOpen = () => {
    setValidation((prevState) => ({
      ...prevState,
      openDrawer: !prevState.openDrawer,
    }));
  };

  const handleMainChange = (target) => (e) => {
    setRiskProfile((prevState) => ({
      ...prevState,
      [target]: e.target.value,
    }));
  };

  const handleRiskMessage = (message) => {
    setRiskType({
      riskProfileType: message,
      riskProfileMessageOpen: true,
    });
  };

  const computeRiskProfile = (riskProfile) => {
    let profileScore = 0;

    profileScore += parseInt(riskProfile.selectedInstrumentsInvested);
    profileScore += parseInt(riskProfile.selectedInvestmentPriority);
    profileScore += parseInt(riskProfile.selectedInvestmentFamiliarity);
    profileScore += parseInt(riskProfile.selectedInvestmentType);
    profileScore += parseInt(riskProfile.selectedIncomeStability);
    profileScore += parseInt(riskProfile.selectedPeriodWithoutRedemption);
    profileScore += parseInt(riskProfile.selectedYearsUntilRetirement);
    profileScore += parseInt(riskProfile.selectedWillingRisk);
    profileScore += parseInt(riskProfile.selectedPortfolioComfort);

    if (profileScore >= 9 && profileScore <= 15) {
      profileScore = "conservative";
    } else if (profileScore >= 16 && profileScore <= 22) {
      profileScore = "moderate";
    } else if (profileScore >= 23 && profileScore <= 27) {
      profileScore = "aggressive";
    } else {
      profileScore = "ERROR";
    }
    return profileScore;
  };

  const handleModalClose = () => {
    setModalAlert((prevState) => ({ ...prevState, modalOpen: false }));
    if (modalAlert.modalSuccess) {
      props.history.push(ROUTES.DASHBOARD);
    }
  };


      // const handleAgree = () => {
      //   setMain((prevState) => ({
      //     ...prevState,
      //     agreeKYC: !prevState.agreeKYC,
      //   }));
      // };

      const stringToWhiteSpaceConverter = (text) => {
        let returnText = text.replace(/\/n /gi, `\n`);
        return returnText;
      };
        const toggleComments = () => {
          setComment((prevState) => ({
            ...prevState,
            showComments: !prevState.showComments,
          }));
        };


  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return (
          <ClientInformation
            clientInfo={clientInfo}
            uploadImg={uploadImg}
            editKYC={true}
            mariageOption={mariageOption}
            handleMarriage={handleChangeMarriage}
            handleMobileNumber={handleMobileNumber}
            handleDropzone={handleDropzone}
            handleDropzoneDelete={handleDropzoneDelete}
            nextStep={nextStep}
            fixedStep={fixedStep}
            smBreakpoint={smBreakpoint}
            handleChangeData={handleChangeClientInfo}
            initialLoad={initialLoad}
          />
        );
      case 2:
        return (
          <RiskProfiler
            riskProfile={riskProfile}
            riskType={riskType}
            handleMainChange={handleMainChange}
            prevStep={prevStep}
            nextStep={nextStep}
            fixedStep={fixedStep}
            handleRiskMessage={handleRiskMessage}
            computeRiskProfile={computeRiskProfile}
          />
        );
      case 3:
        return (
          <Summary
            modalAlert={modalAlert}
            loading={validation}
            riskType={riskType}
            clientInfo={clientInfo}
            prevStep={prevStep}
            saveAndContinue={saveAndContinue}
            handleModalClose={handleModalClose}
            isUpdateKYC={true}
          />
        );

      default:
        return <BasicKYCHandler history={props.history} />;
    }
  };

      
    let showComments =
      comments.showComments !== undefined ? comments.showComments : true;

 const drawer = (
   <>
     <div className={classes.drawerHeader}>
       <img className={classes.brand} src={logo2} alt="rampver-logo"></img>

       {!validation.openDrawer ? (
         ""
       ) : (
         <IconButton onClick={() => handleDrawerOpen()}>
           {theme.direction === "ltr" ? (
             <ChevronLeftIcon style={{ color: "white" }} />
           ) : (
             <ChevronRightIcon style={{ color: "white" }} />
           )}
         </IconButton>
       )}
     </div>
     <div className="drawer-container">
       <label className="drawer-title" style={{ color: "white" }}>
         Client Application
       </label>
       <p className="drawer-subtitle" style={{ color: "white" }}>
         Please make sure all your personal information are correct and
         consistent with your government issued ID&apos;s.
       </p>
       <div className="drawer-item-list">
         <List>
           {["Client Information", "Risk Profiler", "Summary"].map(
             (item, index) => {
               const stepNavigation = index + 1;
               return (
                 <ListItem
                   onClick={() =>
                     step.fixStep >= index + 1 &&
                     setStep((prevState) => ({
                       ...prevState,
                       step: stepNavigation,
                     }))
                   }
                   style={{
                     cursor: step.fixStep >= index + 1 ? "pointer" : "default",
                   }}
                   key={index}
                 >
                   <ListItemIcon style={{ marginRight: "0" }}>
                     {step.fixStep > index + 1 ? (
                       <CheckCircleOutlineIcon color="primary" />
                     ) : (
                       <ChevronRightIcon style={{ color: "white" }} />
                     )}
                   </ListItemIcon>
                   <ListItemText
                     className={clsx(
                       "list-item-default",
                       step.fixStep > index + 1 ? "process-completed" : "",
                       step.step === index + 1 ? "process-current" : ""
                     )}
                   >
                     <span
                       className={`${
                         step.fixStep >= index + 1 && "sidebar-text"
                       }`}
                     >
                       {" "}
                       {item}{" "}
                     </span>
                   </ListItemText>
                 </ListItem>
               );
             }
           )}
         </List>
       </div>
     </div>
   </>
 );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, classes.navBar)}
        >
          <Toolbar>
            <IconButton
              style={{ color: "#256141" }}
              aria-label="open drawer"
              onClick={() => handleDrawerOpen()}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <img className={classes.brand2} src={logo} alt="rampver-logo"></img>
          </Toolbar>
        </AppBar>

        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={validation.openDrawer}
            onClose={() => handleDrawerOpen()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div className={classes.mainContent}>
          <main className={clsx(classes.content)}>
            <div>
              {smBreakpoint ? (
                <Grid item xs={12} lg={12}>
                  <Paper elevation={6} className={classes.commentStyle}>
                    <IconButton onClick={(e) => toggleComments(e)}>
                      {showComments ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowDropUpIcon />
                      )}
                    </IconButton>
                    <b>Admin Comments: </b>
                    <br />
                    {showComments ? (
                      <div style={{ overflow: "auto", width: "100%" }}>
                        <span style={{ "white-space": "pre-line" }}>
                          {/* {comments.comments}  */}
                          {stringToWhiteSpaceConverter(comments.comments)}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </Paper>
                </Grid>
              ) : (
                <Grid item xs={12} lg={12}>
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="warning"
                    style={{
                      position: "fixed",
                      zIndex: "999",
                      backgroundColor: "#256141",
                      top: "80px",
                      width:"85%"
                    }}
                    icon={
                      <IconButton onClick={(e) => toggleComments(e)}>
                        {showComments ? (
                          <ArrowDropDownIcon style={{ color: "white" }} />
                        ) : (
                          <ArrowDropUpIcon style={{ color: "white" }} />
                        )}
                      </IconButton>
                    }
                  >
                    <b>Admin Comments: </b>
                    <br />
                    {showComments ? (
                      <div style={{ overflow: "auto", width: "100%" }}>
                        <span style={{ "white-space": "pre-line" }}>
                          {/* {comments.comments} */}
                          {stringToWhiteSpaceConverter(comments.comments)}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </MuiAlert>
                </Grid>
              )}
            </div>
            <div>
              <h2>Edit Client Application</h2>
            </div>
            <div className={classes.drawerHeader} />
            <Container component="main">{renderSwitch(step.step)}</Container>
          </main>
        </div>
      </div>
    </>
  );
};



const condition = (authUser) => !!authUser;



export default compose(withAuthorization(condition), withFirebase)(DeclinedPage);


